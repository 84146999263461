<template>
  <b-overlay :show="loading" rounded="sm" no-fade>
          <validation-observer ref="simpleRules">


        <b-card class="card-app-design">
          <div class="card-header">
            <b-card-title id="card-title" class="mt-1">
              Inventory Pack Formula
            </b-card-title>
          </div>

          <div class="card-description">
            <b-row>
              <b-col cols="12" lg="4">
                <span class="mb-2">
                  Basic Pack
                </span>
                <validation-provider name="Original Daily Price" #default="{ errors }" rules="required">
                  <b-form-input v-model="dailyPrice" type="number" class="mb-2" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col cols="12" lg="4">
                <span class="mb-2">
                  Until (Days)
                </span>
                <validation-provider name="Days" #default="{ errors }" rules="required">
                  <b-form-input v-model="untilDays" type="number" class="mb-2" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              
              </b-col>
              <b-col cols="12" lg="4">
                <span class="mb-2">
                 Additional Pack (Purchase Price %)
                </span>
                <validation-provider name="New Price" #default="{ errors }" rules="required">
                  <b-form-input v-model="afterDailyPercentage" type="number" class="mb-2" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              </b-row>
          </div>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="save"  variant="primary" block>
            Update
          </b-button>
        </b-card>
   
          </validation-observer>
  </b-overlay>
</template>

<script>
import { BCard, BBadge, BCardTitle, BCardText, BAvatar, BButton, BRow, BCol } from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import settingsStoreModule from "@/views/settings/settingsStoreModule.js";
import SettingsCard from "@core/components/statistics-cards/SettingsCard.vue";
import Ripple from "vue-ripple-directive";
import router from "@/router";
import axiosIns from "@/libs/axios";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";



export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BButton,
    BAvatar,
    BCardTitle,
    BCardText,
    BCardActions,
    SettingsCard,ValidationProvider, ValidationObserver,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      loading: true,
     dailyPrice:null,
     untilDays:null,
     afterDailyPercentage:null
    };
  },

  methods: {
 


    save() {

       store
        .dispatch('settings/updatePackConfig', {dailyPriceBefore:this.dailyPrice,daysToStart:this.untilDays,dailyPurchasePercentage:this.afterDailyPercentage})
        .then((response) => {

          this.$swal({
            icon: "success",
            title: "The Pack Configuration has been updated!",
            text: "",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });

          
        })
        .catch((error) => {

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong",
              text: "Please try again or report an issue to support",
              icon: "ThumbsDownIcon",
              variant: "danger",
            },
          });


        });


    },

    getItems() {

      
      store.dispatch("settings/getPackConfig").then((res) => {
        this.dailyPrice = res.data.dailyPriceBefore;
        this.untilDays = res.data.daysToStart;
        this.afterDailyPercentage = res.data.dailyPurchasePercentage;
            this.loading = false;


      })
      .catch((error) => {});

     
    },
   
  },
  created() {
    this.getItems();
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = "settings";
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    return {
      // Filter
    };
  },
};
</script>

<style>
.card-app-design {
  height: 350px;
  overflow: auto;
  padding-bottom: 25px;
  margin: 10px;
}
.design-group {
  flex: 1;
  padding: 1em;
  border: solid 0px;
  height: 25%;
}

.card-header {
  display: flex;
  text-align: left;
  padding-left: 0em !important;
  margin: 5px;
}

.card-description {
  height: 25%;
  overflow: hidden;
}

.card-title {
  font-size: 1.2rem !important;
  overflow: hidden;
}

.right {
  float: right;
  padding: 10px;
}

.button-container {
  display: solid;
}

#card-title {
  float: left;
}
</style>
